<template>
  <transition mode="out-in" name="fade">
    <div v-if="show" :class="[`alerta`, `alerta--${type}`]">
      <div v-if="canHide" class="close" @click="fechar"></div>
      <h4>{{ titulo }}</h4>
      <p>{{ mensagem }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["titulo", "mensagem", "type", "idMemoria", "hidable"],
  data() {
    return {
      show: true,
      canHide: true
    };
  },
  methods: {
    fechar() {
      localStorage.setItem(`hint__${this.idMemoria}`, true);
      this.show = false;
    },
  },
  mounted() {
    this.canHide = (this.hidable !== undefined) ? this.hidable : true;
  },
  created() {
    if (localStorage.getItem(`hint__${this.idMemoria}`)) {
      this.show = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.alerta {
  border: 1px solid;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 20px 0;
  padding: 15px;
  position: relative;
  width: 100%;

  &--warning {
    background: lighten($orange, 35%);
    border-color: lighten($orange, 20%);
    color: darken($orange, 28%);

    p {
      color: darken($orange, 18%) !important;
    }
  }

  &--info {
    background: lighten($blue, 45%);
    border-color: lighten($blue, 20%);
    color: darken($blue, 28%);

    p {
      color: darken($blue, 18%) !important;
    }
  }

  &--success {
    background: lighten($green, 35%);
    border-color: lighten($green, 20%);
    color: darken($green, 28%);

    p {
      color: darken($green, 18%) !important;
    }
  }

  &--danger {
    background: lighten($red, 35%);
    border-color: lighten($red, 20%);
    color: darken($red, 28%);

    p {
      color: darken($red, 18%) !important;
    }
  }

  .close {
    cursor: pointer;
    height: 7px;
    opacity: 0.7;
    padding: 4px;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: all 200ms ease;
    width: 7px;

    &:hover {
      opacity: 0.5;
    }

    &:before,
    &:after {
      background-color: #000;
      content: "";
      display: block;
      height: 3px;
      position: absolute;
      width: 100%;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  h4 {
    font-size: 17px;
    font-weight: bold;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    margin: 7px 0 0;
  }
}
</style>
